<script setup lang="ts">
import { Promotion, PromotionType } from '@rediredi/types'
import { useFlag } from '@unleash/proxy-client-vue'

const props = defineProps<{
  context: 'grid' | 'list' | 'details'
  promotion?: Promotion
  type: 'outOfStock' | 'promotion'
}>()

const { t } = useI18n()
const isDiscountsEnabled = useFlag('storefront.discounts')

const promotionType = computed(() => {
  if (props.promotion?.type === PromotionType.Label) return props.promotion?.promotionLabel
  if (props.promotion?.type === PromotionType.Discount)
    return t('globals.promotions.discountType.percentage', {
      value: props.promotion?.promotionPercent || 0,
    })
  if (props.promotion?.type === PromotionType.SellingPrice)
    return t('globals.promotions.discountType.price', {
      value: formatPrice(props.promotion?.promotionPrice?.amount || 0, {
        currency: props.promotion.promotionPrice?.currency,
      }),
    })

  return ''
})

const typeClasses = computed(() => {
  if (props.type === 'promotion' && isDiscountsEnabled.value) return 'bg-red-300'
  return 'bg-carrot-orange'
})

const contextClasses = computed(() => {
  if (props.context === 'grid') {
    return 'left-[-55px] top-[37px] h-5 w-[200px] text-[10px] md:left-[-55px] md:top-[58px] md:h-7 md:w-[250px] md:text-base'
  }

  if (props.context === 'list') {
    return 'left-[-50px] top-[24px] h-[13px] w-[160px] text-[10px]'
  }

  return 'left-[-80px] top-[55px] z-10 h-7 w-[300px] text-sm md:left-[-60px] md:top-[70px] md:h-10 md:text-xl'
})
</script>

<template>
  <div
    class="out-of-stock absolute flex -rotate-45 items-center justify-center overflow-hidden font-extrabold uppercase text-white"
    :class="[contextClasses, typeClasses]"
  >
    <template v-if="props.type === 'outOfStock' || !isDiscountsEnabled">
      {{ $t('globals.products.outOfStock') }}
    </template>
    <template v-else-if="props.type === 'promotion'">
      {{ promotionType }}
    </template>
  </div>
</template>
